* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.panel {
  position: relative;
}

.panel .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 999;
  width: 100%;
  background-color: rgb(177 177 177 / 65%);
}

.backGround {
  /* background-image: url("./glenn-hansen-k4Y01qPNNlA-unsplash.jpg"); */
  background-color: white;
  background-size: 'cover';
  height: 100vh;
  /* color: white; */
  position: relative;
}

.contentStyles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  background: white;
}

.backgroundcolor {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  color: white;
  position: relative;
  height: 100%;
  overflow-y: auto;
}
/* .backgroundcolor::-webkit-scrollbar {
  width: 6px;
  background-color: #000000;
}
.backgroundcolor::-webkit-scrollbar-thumb {
  background-color: #f8d4ff85;
  border-radius: 20px;
}
.backgroundcolor::-webkit-scrollbar-track {
  background-color: #020202;
} */

.container {
  position: 'relative';
  /* height: 70vh; */
}

@media only screen and (max-width: 1400px) {
  .backGround {
    background-size: 80% auto;
  }
}

@media only screen and (min-width: 992) and (max-width: 1400px) {
  .backGround {
    background-size: 80% auto;
  }
}
