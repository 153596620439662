* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: "Noto Sans", sans-serif;
}
.main-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  margin: 0;
}
h3 {
  margin: 0;
}
tr {
  border-style: none;
}

.second-div {
  background-color: #e9e9e9;
  width: 100%;
}
.thard-div {
  margin: 8px 8px 8px 8px;
  background-color: white;
  padding-bottom: 1rem;
}
.fourth-div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 8px 10px 8px 10px;
}
.main_content {
  background-color: hsl(219deg 100% 95%);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.main_content h5 {
  font-size: 18px;
  color: hsl(1deg 58% 38%);
  padding: 5px 0px 0px 0px;
  letter-spacing: 1.5px;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-family: "Rubik", sans-serif !important;
}
.main-content_p-tag {
  padding: 0px 0px 5px 0px;
  letter-spacing: 1px;
  font-weight: 600;
  color: #000000;
}
.main-perent {
  margin: 0px 15px 15px 15px;
}

.content-div {
  margin: 15px 15px 15px 15px;
  border: 2px solid gray;
  border-radius: 18px;
}

.tabel {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid gray;
  overflow: auto;
}
.td {
  border: 1px solid gray;
  border-collapse: collapse;
}
.border-none {
  border: none;
}

.border-bottom {
  border-bottom: none;
}
.border-top {
  border-top: none;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.bottom-1 {
  text-align: end;
  font-weight: 700;
  margin: 12px 12px 12px 12px;
  letter-spacing: 1px;
}

.font-bold {
  font-weight: 600;
  letter-spacing: 1px;
}

.p-t {
  padding-top: 0.3rem;
}
.p-l {
  padding-left: 1rem;
}
.p-b {
  padding-bottom: 0.3rem;
}

.p-r {
  padding-right: 1rem;
}
.letter-spacing {
  letter-spacing: 1px;
}

.tabel-Head-title {
  width: 9rem;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 900;
  color: rgb(0, 0, 0);
}

.font-sizing {
  font-size: 12px;
}

@media print {
  .main_content {
    display: none;
  }
}
