@import './assets/scss/app.scss';

* {
  -ms-overflow-style: none;
}
// ::-webkit-scrollbar {
//   display: none;
// }

body {
  overflow-y: scroll;
  width: 100%;
}

/** input type number arrow hide  */
input[type='number'].no-spinner::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/** input type number arrow hide  */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.validation {
  color: red;
}

.non-validation {
  color: black;
}

.inputValidation {
  border: 2px solid red;
}

.my-custom-classname {
  background-color: aqua;
}

.rbt .rbt-menu {
  background-color: #e5e5e5 !important;
  // box-shadow: 0 0 20px rgb(78 91 137 / 5%) !important;
  display: block !important;
  position: absolute !important;
  will-change: transform !important;
  width: 100% !important;
  top: 10px !important;
  max-height: 300px !important;
  overflow: auto !important;
}
.rbt-close {
  border: none;
}

#name::placeholder {
  color: #ff0000;
  opacity: 1;
}

/* input Color Change css*/

.colorBlack {
  color: black;
}

/* image downloade */

.imageContainer {
  position: absolute !important;
  left: 33%;
  right: 46%;
  top: 43.5%;
  margin: 0px auto;
  max-width: 600px;
}

.fontSize {
  color: red !important;
  font-size: 25px;
  font-weight: 900;
}

@media screen and (max-width: 992px) {
  .imageContainer {
    position: absolute !important;
    left: 31%;
    right: 46%;
    top: 41.5%;
    margin: 0px auto;
    max-width: 600px;
  }
}

@media screen and (max-width: 485px) and (min-width: 428px) {
  .imageContainer {
    position: absolute !important;
    left: 30%;
    right: 46%;
    top: 41.5%;
    margin: 0px auto;
    max-width: 600px;
  }

  .fontSize {
    color: red !important;
    font-size: 20px;
    font-weight: 900;
  }
}
@media screen and (max-width: 428px) and (min-width: 388px) {
  .imageContainer {
    position: absolute !important;
    left: 26%;
    right: 46%;
    top: 40.5%;
    margin: 0px auto;
    max-width: 600px;
  }

  .fontSize {
    color: red !important;
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 388px) and (min-width: 308px) {
  .imageContainer {
    position: absolute !important;
    left: 26%;
    right: 46%;
    top: 40.5%;
    margin: 0px auto;
    max-width: 600px;
  }

  .fontSize {
    color: red !important;
    font-size: 15px;
    font-weight: 900;
  }
}

.margin-zero {
  margin: 0 !important;
}
.searchable-input-container {
  position: relative;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f2f2f2;
}


.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.otp-input {
  width: 40px;
  text-align: center;
  margin: 0 5px;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 18px;
  outline: none;
}

.submit-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.cancel-btn {
  background-color: #dc3545;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px; /* Adjust margin as needed */
}

.cancel-btn:hover {
  background-color: #c82333;
}

.resend-btn {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.resend-btn:hover {
  background-color: #f0f0f0;
}


// dashboard css


.chart-filter{

}

@media (max-width: 425px){
  .chart-filter{
    flex-wrap: wrap;
    gap: 5px !important;
    padding-bottom: 22px;
  }
}


// whats app online offline show

.online-status{
  background: green;
}


.offline-status{
  background: red;
}

/* Default styles for the web view */
#donationReceipt {
  display: none;  /* Hide the receipt on the web page by default */
}
#printableArea {
  display: none;  /* Hide the receipt on the web page by default */
}

/* Print-specific styles */
@media print {
  body {
      visibility: hidden;
  }
  #donationReceipt, #donationReceipt {
      visibility: visible;
      display: block;  
  }
  // #printableArea, #printableArea {
  //     visibility: visible;
  //     display: block;  
  // }
  #hide-content, #hide-content {
      display: none;  
  }
  .page-header{
    display: none;
  }
  .footer{
    display: none;
  }
  #donationReceipt {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
  }
}