* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.panel {
  position: relative;
}

.panel .loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 999;
  width: 100%;
  background-color: rgb(177 177 177 / 65%);
}

.donarbackGround {
  /* background-image: url("./glenn-hansen-k4Y01qPNNlA-unsplash.jpg"); */
  background-color: #00000014;
  background-size: "cover";
  height: 100vh;
  /* color: white; */
  position: relative;
}

.contentStyles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  background: white;
}

.doanrbackgroundcolor {
  /* background: rgba(255, 255, 255, 0.2); */
  /* backdrop-filter: blur(8px); */
  color: black;
  position: relative;
  height: 100%;
  overflow-y: scroll;
}
.doanrbackgroundcolor::-webkit-scrollbar {
  width: 4px;
  background-color: #dddddd;
}
.doanrbackgroundcolor::-webkit-scrollbar-thumb {
  background-color: #5f5f5f85;
  border-radius: 20px;
}
.doanrbackgroundcolor::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.donarcontainer {
  position: "relative";
  /* height: 100vh; */
}

@media only screen and (max-width: 1400px) {
  .doanrbackgroundcolor {
    background-size: 100% auto;
  }
  .donarcontainer {
    height: 80vh;
  }
}

@media only screen and (min-width: 992) and (max-width: 1400px) {
  .doanrbackgroundcolor {
    background-size: 100% auto;
  }
  .donarcontainer {
    height: 80vh;
  }
}
