.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 99999999;
  width: 100%;
  /* background-color: rgb(177 177 177 / 65%); */
  background-color: #b293bf75;
}
